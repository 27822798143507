/* Contact Form */
.c-contact-form { $c: &;

	.container {
		position: relative;
	}

	&__form-box {
		max-width: 580px;
		margin: 0 auto;
		padding-top: 140px;
	}

	&__contact-head {
		margin-bottom: 48px;
		text-align: center;
	}

	&__contact-title {
		display: inline-block;
		margin-bottom: 20px;
		font-size: 58px;
	}

	&__contact-description {
		font-size: 20px;
		font-weight: 600;

		@media only screen and (max-width: $mobile) {
			font-size: 16px;
		}
	}

	&__form {
		position: relative;
		margin-bottom: 60px;
	}

	&__form-group {
		margin-bottom: 20px;
		font-size: 0;

		&--button {
			text-align: center;
		}
	}

	&__form-input {
		width: 100%;
		padding: 22px;
		font-size: 16px;
		font-weight: 500;
		border: 1px solid $border-color;
		border-radius: 8px;
		outline: none;
		transition: $global-transition;
		resize: vertical;
		color: $text-color;
		background: $background-color-alt;

		&::placeholder {
			font-weight: 500;
			color: rgba($text-color, 0.3);
		}

		&:focus {
			border-color: $primary-color;
			box-shadow: 0px 4px 10px rgba($primary-color, 0.25);
		}
	}
}