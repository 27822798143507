/* Sections */
.section {
  padding: 96px 0;

  @media only screen and (max-width: $desktop) {
    padding: 80px 0;
  }

  @media only screen and (max-width: $mobile) {
     padding: 60px 0;
  }
}

.section__info {
  margin-bottom: 60px;

  .section__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .section__title {
      margin: 0;
      font-size: 32px;
      line-height: 1;
    }
  
    .section__link {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      white-space: nowrap;
      will-change: transform;
  
      &:hover {
        color: $text-color;

        i {
          transform: translateX(2px);
        }
      }

      i {
        width: 18px;
        height: 18px;
        margin-left: 4px;
        font-size: 14px;
        line-height: 18px;
        border-radius: 50%;
        text-align: center;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05%);
        background: $background-color-alt;
        transition: .2s;
      }
    }
  }

  .section__description {
    max-width: 480px;
    line-height: 1.6;
    margin: 32px 0 0;

    p {
      margin: 0;
    }
  }

  @media only screen and (max-width: $desktop) {
    .section__description {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 48px;

    .section__head {

      .section__title {
        margin-right: 12px;
        font-size: 28px;
      }

      .section__link {
        font-size: 14px;
      }
    }
  }
}