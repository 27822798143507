/* Posts List */
.c-blog-card {
  margin-bottom: 20px;
  will-change: transform;
  transition: transform .2s;

  &:hover {
    transform: translateY(-4px);

    .article__inner {
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    }
  }

  @media only screen and (max-width: $tablet) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__inner {
    border-radius: 16px;
    overflow: hidden;
    transform: translate(0);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.07);
    transition: box-shadow .2s;
  }

  &__image-wrap {
    background: $background-color-alt;
  }

  &__image {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 71.25%;
    background: $blue-gray;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: $blue-gray;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 28px;
    margin-top: -20px;
    border-radius: 16px 16px 0 0;
    background: $background-color-alt;
  }

  &__tags-box {
    display: flex;
  }

  &__tag {
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 8px 12px;
    margin: 0 4px 4px 0;
    font-size: 12px;
    line-height: 10px;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 8px;
    overflow: hidden;
    color: $white;
    background: linear-gradient(90deg, $primary-color 0%, $secondary-color 167%);

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 8px;
      will-change: transform;
      transition: opacity 0.35s;
      background: linear-gradient(90deg, $primary-color 0%, $secondary-color 107%);
    }

    &:last-child {
      margin: 0 0 4px 0;
    }
  }

  &__title {
    margin: 16px 0;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;

    a {
      &:hover {
        color: $text-color;
      }
    }

    @media only screen and (max-width: $mobile) {
      font-size: 22px;
    }
  }

  &__excerpt {
    display: -webkit-box;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: $text-color;
    overflow-y: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }

  &__author-image {
    position: relative;
    width: 44px;
    height: 44px;
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $blue-gray;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__author-name {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
  }

  &__date {
    font-size: 12px;
    line-height: 14px;
  }
}

  


  

  

  





