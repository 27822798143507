.top {
  position: fixed;
  bottom: 40px;
  right: -100px;
  z-index: 1;
  width: 36px;
  height: 36px;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  background-color: $background-color-alt;
  color: $text-color;
  cursor: pointer;
  transition: all .25s ease;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);

  &.is-active {
    right: 40px;
  }
}

@media only screen and (max-width: $tablet) {
  .top {
    bottom: 25px;

    &.is-active {
      right: 30px;
    }
  }
}