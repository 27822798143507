.c-testimonial-card {

	&__content {
		width: 100%;
		padding: 32px;
		border-radius: 16px;
		color: $white;
		background: linear-gradient(90deg, $primary-color 0%, $secondary-color 167%);
	}

	&__client-meta {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-bottom: 32px;
	}

	&__client-text {
		position: relative;
		margin-bottom: 0;
		font-size: 15px;
		line-height: 1.6;
		text-align: center;

		&::after {
			content: "“";
			position: absolute;
			top: -10px;
			left: -2%;
			display: block;
			z-index: 2;
			font-size: 100px;
			font-weight: 900;
			line-height: 1;
			color: $white;
			opacity: 0.1;
			pointer-events: none;
		}
	}

	&__image-container {
		position: relative;
		width: 80px;
		height: 80px;
		margin-bottom: 8px;
		border-radius: 50%;
		overflow: hidden;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);

		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	&__client-info {
		text-align: center;
	}

	&__client-name {
		margin-bottom: 8px;
		font-size: 21px;
		line-height: 1;
		letter-spacing: normal;
	}

	&__client-position {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		line-height: 1;
		opacity: 0.7;
	}
}