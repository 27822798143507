.c-project-card { $c: &;
	margin-bottom: 20px;
	transition: transform .2s ease;

	&:hover {
		#{$c}__title, #{$c}__subtitle {
			opacity: 1;
			transform: translateZ(0);
			transition: transform .4s cubic-bezier(.165,.85,.45,1) .2s,opacity .4s cubic-bezier(.165,.85,.45,1) .2s;
		}
	}

	@media only screen and (max-width: $tablet) {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__image {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		user-select: none;
		background-color: $blue-gray;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			transition: all .4s ease 0s;
			opacity: 0;
			visibility: hidden;
			background: linear-gradient(360deg, $primary-color 0%, rgba($secondary-color, 0.3) 120.95%);
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 16px;
			background-color: $blue-gray;
			pointer-events: none;
			transition: all .4s ease 0s;
			will-change: transform;
		}

		&:hover {
			&::before {
				opacity: 1;
				visibility: visible;
			}

			img {
				transform: scale(1.05);
			}
		}
	}

	&__content {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		min-height: 280px;
		border-radius: 16px;
		transform: translate(0);
		box-shadow: 0px 0px 30px rgba($primary-color, 0.1);
		overflow: hidden;

		@media only screen and (max-width: $desktop) {
			&::after {
				padding-top: 100%;
			}
		}

		@media only screen and (max-width: $mobile) {
			.project__info {
				padding: 0 32px 32px;
			}
		}

		&::after {
			content: "";
			display: table;
			padding-top: 110%;
		}
	}

	&__info {
		z-index: 1;
		width: 100%;
		margin-top: auto;
		padding: 0 32px 36px;
		text-align: center;
		pointer-events: none;
	}

	&__info-wrap {
		overflow: hidden;
	}

	&__title {
		font-size: 28px;
		line-height: 1.3;
		margin-bottom: 0;
		transform: translate3d(0,100%,0);
	}

	&__subtitle {
		font-size: 16px;
		line-height: 1.4;
		opacity: 0;
		transform: translate3d(0,-100%,0);

		&:hover {
			opacity: 0.7;
		}
	}

	&__title, &__subtitle {
		color: $white;
		opacity: 0;
		transition: transform .4s cubic-bezier(.165,.85,.45,1),opacity .4s cubic-bezier(.165,.85,.45,1);
	}
}